import React from 'react'
import Slider1 from '../../public/img/banner_1_bg.jpg';
import Slider2 from '../../public/img/banner_2_bg.jpg';
import Slider3 from '../../public/img/banner_3_bg.jpg';
import Slider4 from '../../public/img/banner_4_bg.jpg';
import Slider5 from '../../public/img/banner_5_bg.jpg';
import SlickSlider from "react-slick";

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button type="button" className="slick-arrow slick-prev" onClick={onClick}>
      <i className="og-icon-arrow-left"/>
    </button>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <button type="button" className="slick-arrow slick-next" onClick={onClick}>
      <i className="og-icon-arrow-right"/>
    </button>
  );
}

class Slider extends React.Component {
  state = {
    sliderIsInitialized: false
  }
  setSliderInit = state => {
    this.setState({ sliderIsInitialized: state })
  }
  render () {
    let self = this;
    let settings = {
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      prevArrow: <PrevArrow/>,
      nextArrow: <NextArrow/>,
      lazyLoad: true,
      onInit: function() {
        self.setSliderInit(true);
      }
    };
    let maxHeight = this.props.windowHeight ? this.props.windowHeight + 'px' : '100vh';
    return (
      <section id="home" className={`${this.state.sliderIsInitialized ? "initialized" : ""} banner`}>
        <div className="banner__controls"/>
        <SlickSlider {...settings}>
          <div className="banner__item">
            <div className="container">
              <div className="content">
                <span className="text-uppercase intro">Atendimento de suas demandas</span> <span className="br"/>
                <b className="font-regular text-uppercase">com excelência <span className="br"/>e comprometimento</b>
              </div>
            </div>
            <div className="bg" style={{ backgroundImage: `url(${Slider2})`, maxHeight: maxHeight }} />
          </div>
          <div className="banner__item socios">
            <div className="container">
              <div className="content">
                <span className="text-uppercase intro">Foco nos objetivos e</span> <span className="br"/>
                <b className="font-regular text-uppercase">resultados <span className="br"/>de nossos <span className="br"/>clientes</b>
              </div>
            </div>
            <div className="bg" style={{ backgroundImage: `url(${Slider3})`, maxHeight: maxHeight }} />
          </div>
          <div className="banner__item">
            <div className="container">
              <div className="content">
                <span className="text-uppercase intro">Desde 1998</span> <span className="br"/>
                <b className="font-regular text-uppercase">Atuando com <span className="br"/>seriedade, <span className="br"/>confiança e <span className="br"/>credibilidade</b>
              </div>
            </div>
            <div className="bg" style={{ backgroundImage: `url(${Slider1})`, maxHeight: maxHeight }} />
          </div>
          <div className="banner__item">
            <div className="container">
              <div className="content">
                <span className="text-uppercase intro">Adoção de novas tecnologias</span> <span className="br"/>
                <b className="font-regular text-uppercase">Constante <span className="br"/>aperfeiçoamento <span className="br"/>de nossos <span className="br"/>profissionais</b>
              </div>
            </div>
            <div className="bg" style={{ backgroundImage: `url(${Slider4})`, maxHeight: maxHeight }} />
          </div>
          <div className="banner__item">
            <div className="container">
              <div className="content">
                <span className="text-uppercase intro">Estrutura</span> <span className="br"/>
                <b className="font-regular text-uppercase">Moderna e <span className="br"/>atualizada</b>
              </div>
            </div>
            <div className="bg" style={{ backgroundImage: `url(${Slider5})`, maxHeight: maxHeight }} />
          </div>
        </SlickSlider>
      </section>
    )
  }
}

export default Slider
