import React from 'react'
import {fetchCollection, fetchSingleton} from '../cockpit/fetch.js';
import Header from '../components/Header'
import Slider from '../components/Slider'
import About from '../components/About'
import MissionVisionValues from '../components/MissionVisionValues'
import Members from '../components/Members'
import Area from '../components/Area'
import News from '../components/News'
import Footer from '../components/Footer'

class App extends React.Component {
  state = {
    isMenuOpen: false,
    isScrolled: false,
    windowHeight: undefined,
    windowWidth: undefined,
    bodyMenuMaxHeight: undefined,
    config: {},
    navigation: {
      entries: []
    },
    triggerHook: 0.7
  }

  scroll = null
  firstResize = false

  handleResize = () => {
    let self = this
    if (scroll) clearTimeout(self.scroll)
    self.scroll = setTimeout(() => {
      this.setState({
        windowHeight: document.documentElement.clientHeight,
        windowWidth: window.innerWidth,
        triggerHook: (window.innerWidth <= 1024 && window.innerWidth <= document.documentElement.clientHeight) ? 0.85 : 0.7,
        bodyMenuMaxHeight: document.documentElement.clientHeight
          - (document.querySelector('#header > div.container').offsetHeight
            + document.querySelector('#header > div.main-nav > div.main-nav__footer').offsetHeight)
      })
    }, 300);
  }

  componentDidMount () {
    document.querySelector('body').className = 'home-page';

    fetchSingleton('config').then(
      response => {
        this.setState({config: response});
      }
    )

    fetchCollection('menu').then(
      navigation => {
        this.setState({navigation});
      }
    )

    window.addEventListener('resize', this.handleResize)
    if (!this.firstResize) {
      this.firstResize = true;
      setTimeout(() => {
        this.handleResize()
      }, 1000)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  toggleMenu = () => {
    const currentState = this.state.isMenuOpen;
    this.setState({ isMenuOpen: !currentState });
  }

  handleHeaderOnScroll = (offsetY) => {
    let isScrolled = true;

    if (offsetY <= 100){
      isScrolled = false;
    }

    this.setState({ isScrolled: isScrolled });
  }

  scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120; // header height
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    this.setState({ isMenuOpen: false });
  }

  render () {
    return (
      <div>
        <Header config={this.state.config}
                navigation={this.state.navigation}
                isMenuOpen={this.state.isMenuOpen}
                isScrolled={this.state.isScrolled}
                toggleMenu={this.toggleMenu}
                handleHeaderOnScroll={this.handleHeaderOnScroll}
                windowHeight={this.state.windowHeight}
                windowWidth={this.state.windowWidth}
                scrollWithOffset={this.scrollWithOffset}
                isHomePage={true}
                bodyMenuMaxHeight={this.state.bodyMenuMaxHeight}/>
        <Slider windowHeight={this.state.windowHeight} />
        <div className="main-content">
          <div className="main-content__inner">
            <About triggerHook={this.state.triggerHook} />
            <MissionVisionValues triggerHook={this.state.triggerHook} />
            <Members triggerHook={this.state.triggerHook} />
            <Area triggerHook={this.state.triggerHook} />
            <News triggerHook={this.state.triggerHook} />
            <Footer scrollWithOffset={this.scrollWithOffset}
                    isHomePage={true}
                    config={this.state.config}
                    navigation={this.state.navigation} />
          </div>
        </div>
      </div>
    )
  }
}

export default App
