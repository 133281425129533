import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import App from '../pages/App'
import Article from '../pages/Article'

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path='/' component={App} />
      <Route
        path="/noticia/:slug"
        component={({ match }) => (
          <Article active={match.params.slug} />
        )}
      />
      <Route component={App} />
    </Switch>
  </BrowserRouter>
)

export default Router
