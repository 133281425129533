import axios from "axios";
import * as Config from "../config";
// axios.defaults.baseURL = 'http://localhost:1010/'
axios.defaults.headers.common = {'Authorization': `Bearer ${Config.COCKPIT_API_TOKEN}`}

export async function fetchCollection(collection, args) {
  let data = (args) ? args : {};
  return axios
    .post(
      `${Config.COCKPIT_HOST}/api/collections/get/${collection}`,
      data
    )
    .then(collection => collection.data)
    .catch(error => {
      console.error(error);
    });
}

export async function fetchSingleton(singleton) {
  return axios
    .get(
      `${Config.COCKPIT_HOST}/api/singletons/get/${singleton}`
    )
    .then(singletons => singletons.data);
}

export function fetchImageStyle(style, path) {
  if (!path.startsWith(Config.COCKPIT_UPLOADS_DIR)) {
    path = `${Config.COCKPIT_UPLOADS_DIR}${path}`;
  }

  return (
    (style &&
      axios.get(
        `${Config.COCKPIT_HOST}/api/imagestyles/style/${style}?src=${path}`
      )) ||
    `${Config.COCKPIT_HOST}/${path}`
  );
}

export async function submitForm(formName, values) {
  return axios
    .post(
      `${Config.COCKPIT_HOST}/api/forms/submit/${formName}`,
      { form: values }
    )
    .then(response => response.status)
    .catch(error => {
      console.error(error);
      return false;
    });
}
