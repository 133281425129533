import React from 'react'
import * as Config from "../config";
import {fetchSingleton} from '../cockpit/fetch.js';
import {Controller, Scene} from 'react-scrollmagic';
import {gsap} from 'gsap';
import {TextPlugin} from 'gsap/TextPlugin';
import {Tween} from 'react-gsap';
import SlickSlider from "react-slick";

class MissionVisionValues extends React.Component {
  state = {
    responseData: {
      items: []
    },
    changing: false,
    activeContent: null,
    scrollOverride: true,
    sliderIsInitialized: false,
    disablePrevious: true,
    disableNext: false,
  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  constructor(props, context) {
    super(props, context);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentDidMount () {
    fetchSingleton('missao_visao_valores').then(
      responseData => {
        this.setState({responseData});
      }
    )

    gsap.registerPlugin(TextPlugin);

    let self = this,
      mvv = document.querySelector('#missao-visao-valores'),
      scrollCount = 0,
      scroll = null;

    mvv.onwheel = e => {
      if (self.state.scrollOverride) {
        e.preventDefault();
        if (scroll) clearTimeout(scroll);
        scroll = setTimeout(() => {
          scrollCount = 0;
        }, 50);
        if (scrollCount) return;
        scrollCount = 1;

        if (e.deltaY < 0) {
          self.previous();
        } else {
          self.next();
        }
      } else {
        scroll = setTimeout(() => {
          this.setState({scrollOverride: true})
        }, 400);
      }
    }
  }

  handleActive = index => {
    if (this.state.responseData.items[index]) {
      let content = this.state.responseData.items[index].settings;
      this.setState({activeContent: content})
      setTimeout(() => {
        this.setState({changing: false})
      }, 350);
    }
  }

  setSliderInit = state => {
    this.setState({ sliderIsInitialized: state })
  }

  render () {
    let { activeContent, responseData, changing, disableNext, disablePrevious } = this.state,
      self = this,
      settings = {
      // className: "images",
      centerMode: true,
      dots: false,
      arrows: false,
      infinite: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      // swipeToSlide: true,
      beforeChange: function(oldIndex, index) {
        self.handleActive(index)
        index++;

        let disablePrevious = (index === 1);
        let disableNext = (self.slider.props.children.length == index);

        self.setState({
          changing: true,
          scrollOverride: !(index == 1 || self.slider.props.children.length == index),
          disablePrevious: disablePrevious,
          disableNext: disableNext
        })
      },
      onInit: function() {
        self.setSliderInit(true);
      }
    };

    if (this.state.responseData.items.length && activeContent === null) {
      activeContent = responseData.items[0].settings;
    }

    return (<section id="missao-visao-valores" className="section-content">
      {!this.state.responseData.items.length ?
        <div />
        :
        <Controller>
          <Scene
            classToggle="reveal"
            triggerHook={this.props.triggerHook}
          >
            <div className="container">
                <Tween to={{ text: activeContent.title }} duration={0.7}>
                  <h1>Valores</h1>
                </Tween>
              <div className="mvv">
                <SlickSlider ref={c => (this.slider = c)} className="images" {...settings}>
                  {responseData.items.map(item => {
                    if (item.component == 'missao_visao_valores') {
                      return (
                        <div className={item.settings.title_slug} key={item.settings.title_slug}>
                          <div style={{backgroundImage: `url(${Config.WEBSITE_URL}${item.settings.image.path})`}}
                               className="image-item"
                          />
                        </div>
                      );
                    }
                  })}
                </SlickSlider>
              </div>
              <div className="mvv-arrows">
                <button type="button"
                        id="mvv-btn-prev"
                        className={(disablePrevious ? 'prev disabled' : 'prev')}
                        onClick={() => self.previous()}>
                  <span className="material-icons">arrow_back</span>
                </button>
                <button type="button"
                        id="mvv-btn-next"
                        className={(disableNext ? 'next disabled' : 'next')}
                        onClick={() => self.next()}>
                  <span className="material-icons">arrow_forward</span>
                </button>
              </div>
              <div className="subtitle">
                <h2 className={
                  (changing ? "changing" : "")
                } dangerouslySetInnerHTML={{__html: activeContent.description}}/>
              </div>
            </div>
          </Scene>
        </Controller>
      }
    </section>);
  }
}

export default MissionVisionValues;
