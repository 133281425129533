import React from 'react'
import PropTypes from 'prop-types'
import { fetchSingleton, fetchCollection } from '../cockpit/fetch.js';
import Header from '../components/Header'
import ArticleComponent from '../components/Article'
import Footer from '../components/Footer'

class Article extends React.Component {
  slug = React.createRef()

  static propTypes = {
    history: PropTypes.object
  }

  state = {
    isMenuOpen: false,
    isScrolled: false,
    windowHeight: undefined,
    windowWidth: undefined,
    bodyMenuMaxHeight: undefined,
    config: {},
    navigation: {
      entries: []
    }
  }

  handleResize = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      bodyMenuMaxHeight: window.innerHeight
        - (document.querySelector('#header > div.container').offsetHeight
          + document.querySelector('#header > div.main-nav > div.main-nav__footer').offsetHeight)
    })
  }

  componentDidMount () {
    document.querySelector('body').className = 'article-page';

    fetchSingleton('config').then(
      response => {
        this.setState({config: response});
      }
    )

    fetchCollection('menu').then(
      navigation => {
        this.setState({navigation});
      }
    )

    this.handleResize();
    window.addEventListener('resize', this.handleResize)
  }

  componentDidUpdate () {
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  toggleMenu = () => {
    const currentState = this.state.isMenuOpen;
    this.setState({ isMenuOpen: !currentState });
  }

  handleHeaderOnScroll = (offsetY) => {
    let isScrolled = true;

    if (offsetY <= 100){
      isScrolled = false;
    }

    this.setState({ isScrolled: isScrolled });
  }

  scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120; // header height
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    this.setState({ isMenuOpen: false });
  }

  render () {
    let slug = (this.props.active) ? this.props.active : '';
    return (
      <div id="article">
        <Header config={this.state.config}
                navigation={this.state.navigation}
                isMenuOpen={this.state.isMenuOpen}
                isScrolled={this.state.isScrolled}
                toggleMenu={this.toggleMenu}
                handleHeaderOnScroll={this.handleHeaderOnScroll}
                windowHeight={this.state.windowHeight}
                windowWidth={this.state.windowWidth}
                scrollWithOffset={this.scrollWithOffset}
                bodyMenuMaxHeight={this.state.bodyMenuMaxHeight}/>
        <div className="main-content">
          <div className="main-content__inner">
            <div className="article__header text-center">
              <strong>Notícias / Artigos</strong>
            </div>
            <ArticleComponent config={this.state.config} slug={slug} />
            <Footer scrollWithOffset={this.scrollWithOffset}
                    config={this.state.config}
                    navigation={this.state.navigation} />
          </div>
        </div>
      </div>
    )
  }
}

export default Article
