import React from 'react'
import {Controller, Scene} from 'react-scrollmagic';
import {fetchSingleton} from '../cockpit/fetch.js';
import ContentLoader from "react-content-loader";

class About extends React.Component {
  state = {
    responseData: {
      title: null
    }
  };

  componentDidMount () {
    fetchSingleton('quem_somos').then(
      responseData => {
        this.setState({responseData});
      }
    )
  }

  render () {
    let { responseData } = this.state;
    return (<section id="quem-somos" className="section-content quem-somos">
      {responseData.title === null ?
        <div className="text-center loader">
          <ContentLoader
            speed={2}
            width={880}
            height={790}
            viewBox="0 0 880 790"
            backgroundColor="#b3b3b3"
            foregroundColor="#ffffff"
          >
            <rect x="0" y="88" rx="2" ry="2" width="372" height="400" />
            <rect x="506" y="88" rx="2" ry="2" width="372" height="400" />
            <rect x="340" y="0" rx="0" ry="0" width="200" height="28" />
            <rect x="390" y="37" rx="0" ry="0" width="100" height="6" />
            <rect x="66" y="606" rx="0" ry="0" width="306" height="12" />
            <rect x="81" y="629" rx="0" ry="0" width="291" height="12" />
            <rect x="72" y="649" rx="0" ry="0" width="300" height="12" />
            <rect x="81" y="672" rx="0" ry="0" width="291" height="12" />
            <rect x="506" y="546" rx="2" ry="2" width="372" height="220" />
          </ContentLoader>
        </div>
        :
        <Controller>
          <Scene
            classToggle="reveal"
            triggerHook={this.props.triggerHook}
          >
            <div className="container">
              <button type="button" className="page-indicator">Destaques</button>
              <h1><span>{responseData.title}</span></h1>
              <div className="row">
                <div className="col-md-6">
                  <h2 dangerouslySetInnerHTML={{__html: responseData.subtitle}} />
                </div>
                <div className="col-md-5 offset-md-1 content" dangerouslySetInnerHTML={{__html: responseData.content}} />
              </div>
            </div>
          </Scene>
        </Controller>
      }
    </section>);
  }
}

export default About;
