import React from 'react'
import * as Config from "../config";
import {Controller, Scene} from 'react-scrollmagic';
import {fetchSingleton} from '../cockpit/fetch.js';
import loading from '../../public/img/loading.gif'
import Image from "react-bootstrap/Image";
import {Reveal, Tween} from "react-gsap";
import ContentLoader from 'react-content-loader'

class Members extends React.Component {
  state = {
    responseData: {
      image: {
        path: loading
      },
      title: 'Os Sócios',
      socios: []
    },
    active: null
  }

  componentDidMount () {
    fetchSingleton('socios').then(
      responseData => {
        responseData.image.path = Config.WEBSITE_SITE_ROOT + responseData.image.path;
        this.setState({responseData});
        if (responseData.socios.length && !this.state.active) {
          this.setState({active: responseData.socios[0].settings});
        }
      }
    )
  }

  handleClick = socio => {
    if (!this.state.active || this.state.active.name_slug != socio.name_slug) {
      this.setState({active: socio})
      let container = document.getElementById('socios-container');
      container.classList.add('animating');
      setTimeout(() => {
        container.classList.remove('animating');
      }, 400);
    }
  }

  render () {
    let { responseData } = this.state;

    return (
      <section id="socios" className="section-content">
      {!responseData.socios.length || this.state.active === null ?
        <div className="text-center loader">
          <ContentLoader
            speed={2}
            width={700}
            height={300}
            viewBox="0 0 700 300"
            backgroundColor="#b3b3b3"
            foregroundColor="#ffffff"
          >
            <rect x="4" y="8" rx="3" ry="3" width="7" height="288" />
            <rect x="6" y="289" rx="3" ry="3" width="669" height="8" />
            <rect x="670" y="9" rx="3" ry="3" width="6" height="285" />
            <rect x="55" y="42" rx="16" ry="16" width="274" height="216" />
            <rect x="412" y="113" rx="3" ry="3" width="102" height="7" />
            <rect x="402" y="91" rx="3" ry="3" width="178" height="6" />
            <rect x="405" y="139" rx="3" ry="3" width="178" height="6" />
            <rect x="416" y="162" rx="3" ry="3" width="102" height="7" />
            <rect x="405" y="189" rx="3" ry="3" width="178" height="6" />
            <rect x="5" y="8" rx="3" ry="3" width="669" height="7" />
            <rect x="406" y="223" rx="14" ry="14" width="72" height="32" />
            <rect x="505" y="224" rx="14" ry="14" width="72" height="32" />
            <rect x="376" y="41" rx="3" ry="3" width="231" height="29" />
          </ContentLoader>
        </div>
        :
        <div>
          <hr className="page-separator"/>
          <div className="container">
            <button type="button" className="page-indicator">O Escritório</button>
            <Reveal repeat rootMargin="30px">
              <Tween
                from={{ opacity: 0, transform: 'translate3d(0px, 30px, 0px)' }}
                ease="power3.out">
                <h1><span>{responseData.title}</span></h1>
              </Tween>
            </Reveal>
          </div>
          <Controller>
            <Scene
              classToggle="reveal"
              triggerHook={this.props.triggerHook}
            >
              <div>
                <div className="intro">
                  <div className="img">
                    <Image src={responseData.image.path} className="img-fluid" alt=""/>
                  </div>
                </div>
              </div>
            </Scene>
          </Controller>
          <Controller>
            <Scene
              classToggle="reveal"
              triggerHook={this.props.triggerHook}
            >
              <div className="container" id="socios-container">
                <div className="members">
                  <div className="member">
                    <div className="row">
                      <div className="col-img text-center col-6">
                        <img src={`${Config.WEBSITE_URL}${this.state.active.image.path}`}
                             alt={this.state.active.title}
                             className="img-fluid"/>
                      </div>
                      <div className="col-info col-6">
                        <h3>{this.state.active.name}</h3>
                        <h4>Advogado inscrito na {this.state.active.subtitle}</h4>
                        <div className="d-none d-md-block" dangerouslySetInnerHTML={{__html: this.state.active.desc}}/>
                        <a className="mail-link" href={"mailto:" + this.state.active.email}>
                          <i className="og-icon-envelop"/>
                          <span>{this.state.active.email}</span>
                        </a>
                      </div>
                    </div>
                    <div className="m-content d-md-none d-lg-none d-xl-none">
                      <hr className="page-separator h before-nav"/>
                      <div className="c" dangerouslySetInnerHTML={{__html: this.state.active.desc}}/>
                      <hr className="page-separator f before-nav"/>
                    </div>
                    <div className="row nav">
                      <div className="col-nav col-md-7 offset-md-5">
                        <ul className="row justify-content-end">
                          {responseData.socios.map(socio => {
                            if (socio.component == 'socio') {
                              return (
                                <li className="col" key={socio.settings.name_slug}>
                                  <a href={"#/socio/" + socio.settings.name_slug}
                                     className={
                                       socio.settings.name_slug
                                       + (socio.settings.name_slug  === this.state.active.name_slug ? " active" : "")
                                     }
                                     onClick={() => this.handleClick(socio.settings)}>
                                    <span className="photo">
                                      <img src={`${Config.WEBSITE_URL}${socio.settings.image_sm.path}`}
                                           alt={socio.settings.name}/>
                                    </span>
                                    <span className="content">
                                      <b>{socio.settings.name}<br/>{socio.settings.subtitle}</b>
                                    </span>
                                  </a>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="page-separator after-members d-none d-md-block"/>
              </div>
            </Scene>
          </Controller>
        </div>
      }
    </section>);

    // return (
    //   <section id="socios" className="section-content">
    //
    //   </section>
    // )
  }
}

export default Members;
