import React from 'react'
import SlickSlider from "react-slick";
import {Link} from "react-router-dom";
import {fetchCollection} from "../cockpit/fetch";

class NewsList extends React.Component {
  state = {
    relatedNews: []
  }

  componentDidMount () {
    fetchCollection('Noticias', {
      filter: {published:true},
      limit: 10,
      sort: {_created:-1}
    }).then(
      responseData => {
        let entries = (responseData.entries && responseData.entries.length) ? responseData.entries : [];
        this.setState({relatedNews: entries});
      }
    )
  }

  render () {
    let settings = {
      centerMode: false,
      dots: true,
      arrows: true,
      infinite: false,
      slidesToScroll: 1,
      slidesToShow: 3,
      autoplay: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    let { relatedNews } = this.state,
        currentSlug = (this.props.currentSlug) ? this.props.currentSlug : '';

    return (<section>
      {relatedNews.length &&
        <div>
          <div id="news-list">
            <div className="container">
              <SlickSlider className="row" {...settings}>
                {relatedNews.map(relatedArticle => {
                  if (relatedArticle.title_slug != currentSlug) {
                    let excerpt = relatedArticle.excerpt ? relatedArticle.excerpt.substring(0, 150) + '...' : '';
                    return (
                      <div key={relatedArticle.title_slug} className="list-item">
                        <div className="card">
                          <div className="card-body">
                            <Link to={`/noticia/${relatedArticle.title_slug}`}>
                              <h5 className="card-title">{relatedArticle.title}</h5>
                            </Link>
                            <div className="card-text">
                              <div dangerouslySetInnerHTML={{__html: excerpt}}/>
                            </div>
                          </div>
                          <Link to={`/noticia/${relatedArticle.title_slug}`} className="card-link btn btn-warning btn-sm">
                            Leia Mais
                          </Link>
                        </div>
                      </div>
                    )
                  }
                })}
              </SlickSlider>
            </div>
          </div>
        </div>
      }
    </section>);
  }
}

export default NewsList
