import React from 'react'
import {fetchCollection} from '../cockpit/fetch.js';
import {Redirect} from "react-router-dom";
import * as Config from "../config";
import ContentLoader from 'react-content-loader'
import NewsList from '../components/NewsList'

class Article extends React.Component {
  state = {
    responseData: {
      title: null,
      content: null,
      image: false
    },
    notFound: false,
    created: null
  }

  pad = (n) => {
    return n < 10 ? '0'+n : n;
  }

  loadArticle = (slug) => {
    let self = this;

    fetchCollection('Noticias', {
      filter: {
        published:true,
        title_slug: slug
      },
      limit: 1,
      sort: {_created:-1}
    }).then(
      responseData => {
        if (responseData.entries.length) {
          let date = new Date(responseData.entries[0]._created * 1000);
          let minutes = "0" + date.getMinutes();
          let seconds = "0" + date.getSeconds();
          let formattedTime = date.getHours() + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
          let dateString = self.pad(date.getDay()) + "/" + self.pad((date.getMonth() + 1)) + "/" + date.getFullYear();

          this.setState({
            responseData: responseData.entries[0],
            created: dateString + ' ' + formattedTime
          });

          // This prevents the page from scrolling down to where it was previously.
          // if ('scrollRestoration' in history) {
          //   history.scrollRestoration = 'manual';
          // }

          window.scrollTo(0,0);
        } else {
          this.setState({notFound: true})
        }
      }
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && this.props && prevProps.slug && this.props.slug && prevProps.slug != this.props.slug) {
      this.loadArticle(this.props.slug)
    }
  }

  componentDidMount () {
    this.loadArticle(this.props.slug)
  }

  render () {
    let { responseData, notFound } = this.state;
    let settings = {
      centerMode: false,
      dots: true,
      arrows: true,
      infinite: false,
      slidesToScroll: 1,
      slidesToShow: 3,
      autoplay: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };

    if (notFound) {
      return <Redirect to='/' />
    }

    const MainImage = () => {
      let config = this.props.config,
          src = Config.WEBSITE_URL + config?.imagem_noticias?.path,
          alt = '';

      if (responseData.image) {
        src = Config.WEBSITE_URL + responseData.image.path,
        alt = responseData.image.description ? responseData.image.description : '';
      }

      return (
        <div className="image">
          <figure className="figure">
            <img src={src}
                 alt={alt}
                 className="img-fluid"/>
              <figcaption className="figure-caption">{alt}</figcaption>
          </figure>
        </div>
      )
    }

    return (<section className="section-article">
      {!responseData || responseData.title === null ?
        <div className="text-center">
          <ContentLoader
            viewBox="0 0 400 160"
            height={160}
            width={400}
            speed={1}
          >
            <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
            <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
            <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
            <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
            <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
            <rect x="0" y="99" rx="5" ry="5" width="400" height="200" />
          </ContentLoader>
        </div>
        :
        <div className="w">
          <article className="container">
            <div className="text-right publish">
              Publicado: <time dateTime={this.state.created} pubdate="pubdate">{this.state.created}</time>
            </div>
            <h1 className="text-center article-title">{responseData.title}</h1>
            {MainImage()}
            <div className="content article-content" dangerouslySetInnerHTML={{__html: responseData.content}} />
          </article>
          <h3 className="section-title text-center"><span>Outras Notícias/Artigos</span></h3>
          <NewsList currentSlug={responseData.title_slug} />
          }
        </div>
      }
    </section>);
  }
}

export default Article
