import React from 'react'
import {Reveal, Tween} from "react-gsap";
import NewsList from "./NewsList";

class News extends React.Component {
  state = {
  }

  render () {
    let { responseData } = this.state;

    return (
      <section id="noticias" className="section-content">
        <div className="container">
          <Reveal repeat rootMargin="200px">
            <Tween
              from={{ opacity: 0, transform: 'translate3d(0, -50px, 0px)' }}
              duration={0.8}
              ease="power3.out">
              <h1><span>Notícias/Artigos</span></h1>
            </Tween>
            <NewsList />
          </Reveal>
        </div>
    </section>);
  }
}

export default News;
