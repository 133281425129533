import React from 'react'
import {fetchSingleton} from '../cockpit/fetch.js';
import {Reveal, Tween} from "react-gsap";
import ContentLoader from 'react-content-loader'

class Area extends React.Component {
  state = {
    responseData: {
      title: '',
      desc: '',
      areas_left: [],
      areas_right: []
    }
  }

  componentDidMount () {
    fetchSingleton('areas_de_atuacao').then(
      responseData => {
        this.setState({responseData});
      }
    )
  }

  render () {
    let { responseData } = this.state;

    return (<section id="areas-de-atuacao" className="section-content">
      {this.state.responseData.title === '' ?
        <div className="text-center loader">
          <ContentLoader
            speed={2}
            width={880}
            height={180}
            viewBox="0 0 880 180"
            backgroundColor="#b3b3b3"
            foregroundColor="#ffffff"
          >
            <rect x="0" y="3" rx="3" ry="3" width="390" height="6" />
            <rect x="0" y="19" rx="3" ry="3" width="360" height="6" />
            <rect x="0" y="35" rx="3" ry="3" width="178" height="6" />

            <rect x="0" y="70" rx="3" ry="3" width="360" height="6" />
            <rect x="0" y="86" rx="3" ry="3" width="380" height="6" />
            <rect x="0" y="102" rx="3" ry="3" width="250" height="6" />

            <rect x="0" y="135" rx="3" ry="3" width="260" height="6" />
            <rect x="0" y="150" rx="3" ry="3" width="360" height="6" />
            <rect x="0" y="167" rx="3" ry="3" width="320" height="6" />

            <rect x="470" y="3" rx="3" ry="3" width="350" height="6" />
            <rect x="470" y="19" rx="3" ry="3" width="380" height="6" />
            <rect x="470" y="35" rx="3" ry="3" width="178" height="6" />

            <rect x="470" y="70" rx="3" ry="3" width="360" height="6" />
            <rect x="470" y="86" rx="3" ry="3" width="380" height="6" />
            <rect x="470" y="102" rx="3" ry="3" width="250" height="6" />

            <rect x="470" y="135" rx="3" ry="3" width="260" height="6" />
            <rect x="470" y="150" rx="3" ry="3" width="360" height="6" />
            <rect x="470" y="167" rx="3" ry="3" width="320" height="6" />
          </ContentLoader>
        </div>
        :
        <div className="container">
          <Reveal repeat rootMargin="200px">
            <Tween
              from={{ opacity: 0, transform: 'translate3d(0px, 80px, 0px)' }}
              duration={0.8}
              ease="power3.out">
              <h1><span>{responseData.title}</span></h1>
            </Tween>
          </Reveal>

          <Reveal repeat rootMargin="200px">
            <Tween
              from={{ opacity: 0, transform: 'translate3d(0px, 80px, 0px)' }}
              duration={0.8}
              ease="power3.out">
              <div className="st text-center" dangerouslySetInnerHTML={{__html: responseData.desc}} />
            </Tween>
          </Reveal>

          <div className="row list">
            <div className="col-md-6">
              {responseData.areas_left.map((item, index) => {
                if (item.component == 'text') {
                  return (
                    <Reveal repeat rootMargin="200px" key={`area_left${index+1}`}>
                      <Tween
                        from={{ opacity: 0, transform: 'translate3d(-100px, 0px, 0px)' }}
                        duration={0.8}
                        ease="power3.out">
                        <div key={`line${index+1}`}
                             className={`line${index+1}`}
                             dangerouslySetInnerHTML={{__html: item.settings.text}} />
                      </Tween>
                    </Reveal>
                  );
                }
              })}
            </div>
            <div className="col-md-6">
              {responseData.areas_right.map((item, index) => {
                if (item.component == 'text') {
                  return (
                    <Reveal repeat rootMargin="200px" key={`area_right${index+1}`}>
                      <Tween
                        from={{ opacity: 0, transform: 'translate3d(100px, 0px, 0px)' }}
                        duration={0.8}
                        ease="power3.out">
                        <div className={`line${index+1}`}
                             dangerouslySetInnerHTML={{__html: item.settings.text}} />
                      </Tween>
                    </Reveal>
                  );
                }
              })}
            </div>
          </div>
        </div>
      }
    </section>);
  }
}

export default Area;
